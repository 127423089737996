import React from 'react';
import styled from 'styled-components';
import { STRUKD_API_TEXT, HEADER_FOOTER_HEIGHT_PX } from './constants';
import Logo from '../logo.svg';

const HomeContainer = styled.div(props => ({
    backgroundColor: props.theme.bgColor,    
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
}));

const NameContainer = styled.div(props => ({
    backgroundColor: props.theme.bgColor,
    minHeight: `calc(100vh - 2 * ${HEADER_FOOTER_HEIGHT_PX})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: props.theme.textPrimary,
}));

const LogoTitleContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Title = styled.h1({
    margin: '0 0 10px 0'
});

const VerticalLine = styled.div(props => ({
    height: '50px',
    width: '2px',
    backgroundColor: props.theme.primaryColor,
    margin: '0 15px',
}));

const Punchline = styled.span(props => ({
    display: 'block',
    fontSize: 'calc(10px + 1.5vmin)',
    fontWeight: '500',
    marginTop: '0px',
    color: props.theme.primaryColor,
    textTransform: 'uppercase',
    fontStyle: 'italic'
}));

const Home: React.FC = () => (
    <HomeContainer>
        <NameContainer>
            <LogoTitleContainer>
                <img src={Logo} alt="Logo" style={{ width: "3em" }} />
                <VerticalLine />
                <Title>{STRUKD_API_TEXT}</Title>
            </LogoTitleContainer>
            <Punchline>where data finds structure</Punchline>
        </NameContainer>
    </HomeContainer>
);

export default Home;
