import React, { useState } from 'react';
import styled from 'styled-components';
import { HEADER_FOOTER_HEIGHT } from './constants';

const IFRAME_URL = "https://cdn.forms-content-1.sg-form.com/ce1567de-3ad6-11ee-86d9-62fc54ff7218";

const SignupFormContainer = styled.div(props => ({
    backgroundColor: props.theme.bgColor,
    minHeight: `calc(100vh - ${HEADER_FOOTER_HEIGHT * 4}px)`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 20px'
}));

const ContentWrapper = styled.div({
    maxWidth: '800px',
    width: '100%',
    textAlign: 'left'
});

const SignupTitle = styled.h2(props => ({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '30px',
    color: props.theme.textSecondary,
}));

const SignupText = styled.p(props => ({
    fontSize: '1.1rem',
    marginBottom: '15px',
    lineHeight: '1.6',
    maxWidth: '800px',
    color: props.theme.textPrimary,
}));

const SignupButton = styled.button(props => ({
    padding: '12px 24px',
    fontSize: '1.2rem',
    backgroundColor: props.theme.primaryColor,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '20px',
    '&:hover': {
        backgroundColor: props.theme.secondaryColor,
    }
}));

const ModalOverlay = styled.div<{ show: boolean }>(props => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: props.theme.bgColor,
    display: props.show ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
}));

const ModalContent = styled.div(props => ({
    position: 'relative',
    width: '80%',
    maxWidth: '500px',
    minHeight: '500px',
    backgroundColor: props.theme.bgColor,
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
    textAlign: 'center',
    overflow: 'auto',
    maxHeight: '80vh',
}));

const CloseButton = styled.button({
    position: 'absolute',
    right: '10px',
    top: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
    cursor: 'pointer',
    '&:hover': {
        color: 'red',
    }
});

const StyledIframe = styled.iframe({
    width: '100%',
    height: '550px',
    border: 'none',
});

const Signup: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <SignupFormContainer>
            <ContentWrapper>
                <SignupTitle>Unified Billing Data Access</SignupTitle>
                <SignupText>Strukd API centralizes your billing data, simplifying management and analysis.</SignupText>
                <SignupText>Seamlessly integrate with existing systems for always-accurate, up-to-date billing information.</SignupText>
                <SignupText>From startups to enterprises, Strukd API scales to your needs, empowering you to enhance billing processes and boost revenue.</SignupText>
                <SignupButton onClick={handleOpenModal}>Get Started</SignupButton>
            </ContentWrapper>
            <ModalOverlay show={showModal}>
                <ModalContent>
                    <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
                    <StyledIframe src={IFRAME_URL} />
                </ModalContent>
            </ModalOverlay>        
        </SignupFormContainer>
    );
};

export default Signup;
