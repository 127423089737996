import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HEADER_FOOTER_HEIGHT_PX } from './constants';

const FooterComponent = styled.div(props => ({
    padding: '10px 0',
    backgroundColor: props.theme.bgColor,
    minHeight: HEADER_FOOTER_HEIGHT_PX,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: props.theme.textSecondary
}));

const Footer: React.FC = () => {
    const location = useLocation();
    const year = new Date().getFullYear();
    
    if (location.pathname === "/") {
      return null;
    }
    
    return (
      <FooterComponent>
        <span>© {year} Strukd, Inc.</span>
      </FooterComponent>
    );
  }
  
  export default Footer;
