import React from 'react';
import styled from 'styled-components';
import { RedocStandalone } from 'redoc';

const OPENAPI_URL = "https://strukd.com/openapi/swagger.yaml";

const RedocWrapper = styled.div`
  .INTUa {
    display: none;
  }
`;

const Home: React.FC = () => (
  <RedocWrapper>
    <RedocStandalone 
      specUrl={OPENAPI_URL}
      options={{ 
        hideFab: false
      }}
    />
  </RedocWrapper>
);

export default Home;
