import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${(props) => props.theme.bgColor};
  color: ${(props) => props.theme.textPrimary};
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.textSecondary};
  font-size: 2.5rem;
  margin-bottom: 40px;
`;

const PlanContainer = styled.div`
  width: 70%; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  background-color: ${(props) => props.theme.cardBgColor};
  color: ${(props) => props.theme.primaryColor};
  margin: 0 auto 30px; 
`;

const PlanTitle = styled.h3`
  font-size: 1.5rem;
  color: ${(props) => props.theme.logoColors.primary};
  margin-bottom: 15px;
  text-align: center;
`;

const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 10px;
  align-items: start; /* Align items to the top */
  margin-bottom: 20px;
`;

const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureTitle = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;

const FeatureDescription = styled.div`
  font-size: 1rem;
`;

const Price = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  padding: 5px 10px;
  color: ${(props) => props.theme.logoColors.tertiary};
  align-self: center;
  min-width: 140px;
  text-align: center;
`;

const SpecialOffer = styled.div`
  background-color: ${(props) => props.theme.logoColors.secondary};
  color: ${(props) => props.theme.textPrimary};
  padding: 15px;
  margin-top: 30px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;


const Pricing: React.FC = () => {
    return (
        <Container>
            <Title>Pricing Plans</Title>

            <PlanContainer>
                <PlanTitle>Free Plan</PlanTitle>
                <FeatureContainer>
                    <FeatureTitle>Test Biller - Always free</FeatureTitle>
                </FeatureContainer>
                <FeatureContainer>
                    <FeatureTitle>One User Account per API Client</FeatureTitle>
                </FeatureContainer>
                <FeatureContainer>
                    <FeatureTitle>Basic Account Linking</FeatureTitle>
                </FeatureContainer>
            </PlanContainer>

            <PlanContainer>
                <PlanTitle>Standard Plan</PlanTitle>

                <FeatureContainer>
                    <FeatureTitle>Account Linking</FeatureTitle>
                    <Price>$1</Price>
                    <FeatureDescription>account balance, due date, biller-specific metadata</FeatureDescription>
                </FeatureContainer>

                <FeatureContainer>
                    <FeatureTitle>Data Refresh</FeatureTitle>
                    <Price>$1</Price>
                    <FeatureDescription>refreshes data from a linked account</FeatureDescription>
                </FeatureContainer>

                <FeatureContainer>
                    <FeatureTitle>Verification</FeatureTitle>
                    <Price>$1</Price>
                    <FeatureDescription>verify name and or address against biller records</FeatureDescription>
                </FeatureContainer>

                <FeatureContainer>
                    <FeatureTitle>PII Access</FeatureTitle>
                    <Price>$2</Price>
                    <FeatureDescription>legal name, address, phone, email, etc.</FeatureDescription>
                </FeatureContainer>

                <FeatureContainer>
                    <FeatureTitle>Access a PDF statement</FeatureTitle>
                    <Price>$4</Price>
                    <FeatureDescription>per statement, per account</FeatureDescription>
                </FeatureContainer>

                <FeatureContainer>
                    <FeatureTitle>Custom Site Integration</FeatureTitle>
                    <Price>$500+</Price>
                    <FeatureDescription>Custom solutions for unique needs, priced by complexity.</FeatureDescription>
                </FeatureContainer>
            </PlanContainer>

            <PlanContainer>
                <PlanTitle>Enterprise Plan</PlanTitle>

                <FeatureContainer>
                    <FeatureTitle>Bespoke Site Integration</FeatureTitle>
                </FeatureContainer>
                <FeatureContainer>
                    <FeatureTitle>Priority Support</FeatureTitle>
                </FeatureContainer>
                <FeatureContainer>
                    <FeatureTitle>Advanced Data Refresh Options</FeatureTitle>
                </FeatureContainer>
                <FeatureContainer>
                    <FeatureTitle>Unlimited API Calls</FeatureTitle>
                    <Price>Contact for Pricing</Price>
                </FeatureContainer>

            </PlanContainer>

            <SpecialOffer>
                Special Offer: Get a one-year 50% discount for each new biller integrated with your assistance!
            </SpecialOffer>
        </Container>
    );
};

export default Pricing;
