import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { STRUKD_API_TEXT, HEADER_FOOTER_HEIGHT_PX } from './constants';
import Pricing from "./Pricing";

const StyledLink = styled(Link)(props => ({
    padding: '10px 0',
    color: props.theme.textSecondary,
    textDecoration: 'none',
    marginLeft: '40px',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const StyledAnchor = styled.a(props => ({
    padding: '10px 0',
    color: props.theme.textSecondary,
    textDecoration: 'none',
    marginLeft: '40px',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const StrukdAPIText = styled.span(props => ({
    color: props.theme.textPrimary,
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginRight: 'auto',
    marginLeft: '20px',
    padding: '10px 0',
}));

const HeaderComponent = styled.header(props => ({
    backgroundColor: props.theme.bgColor,
    minHeight: HEADER_FOOTER_HEIGHT_PX,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '10px 20px',
    color: props.theme.textSecondary
}));


const Footer: React.FC = () => {
    const location = useLocation();
    return (
        <HeaderComponent>
            {location.pathname !== "/" && <StrukdAPIText>{STRUKD_API_TEXT}</StrukdAPIText>}
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/developers">Developers</StyledLink>
            <StyledLink to="/pricing">Pricing</StyledLink>
            <StyledLink to="/signup">Signup</StyledLink>
        </HeaderComponent>
    );
};

export default Footer;
