export interface ThemeType {
    primaryColor: string;
    secondaryColor: string;
    bgColor: string;
    textPrimary: string;
    textSecondary: string;
    cardBgColor: string;
    logoColors: {
        primary: string;
        secondary: string;
        tertiary: string;
        accent: string;
    };
}

const theme: ThemeType = {
    primaryColor: '#34495E',
    secondaryColor: '#2980B9',
    bgColor: '#282c34',
    textPrimary: '#FFFFFF',
    textSecondary: '#90A4AE',
    cardBgColor: '#E8EAF6',
    logoColors: {
        primary: '#554D84',
        secondary: '#FF6A6B',
        tertiary: '#2DB296',
        accent: '#FBB343',
    }
};

const logoColors = {
    primary: '#554D84',
    secondary: '#FF6A6B',
    tertiary: '#2DB296',
    accent: '#FBB343',
};

export default theme;
  