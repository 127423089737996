import React from 'react';
import './App.css';
import styled from 'styled-components';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import Header from './components/Header';
import Home from './components/Home';
import Developers from './components/Developers';
import Signup from './components/Signup';
import Footer from './components/Footer';
import Pricing from "./components/Pricing";

const AppContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  justifyContent: 'space-between'
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContainer>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/developers" element={<Developers />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
          <Footer />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
